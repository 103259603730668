import React from "react"
import {
  StyledFeedbackItem,
  StyledFeedbackItemTitleContainer,
  StyledFeedbackItemFooter,
  StyledFeedbackItemTitle,
  StyledQuote,
  StyledClientContainer,
  StyledFeedbackItemClientName,
  StyledFeedbackItemClientRole,
  StyledProductImageContainer,
  StyledProductImage,
} from "../styled"

const FeedbackItem = ({ title, author, role, image }) => {
  return (
    <StyledFeedbackItem>
      <StyledFeedbackItemTitleContainer>
        <StyledQuote />
        <StyledFeedbackItemTitle>{title}</StyledFeedbackItemTitle>
      </StyledFeedbackItemTitleContainer>
      <StyledFeedbackItemFooter>
        <StyledClientContainer>
          <StyledFeedbackItemClientName>{author}</StyledFeedbackItemClientName>
          <StyledFeedbackItemClientRole>{role}</StyledFeedbackItemClientRole>
        </StyledClientContainer>
        <StyledProductImageContainer>
          <StyledProductImage
            height={image?.height}
            width={image?.width}
            src={image?.icon}
          />
        </StyledProductImageContainer>
      </StyledFeedbackItemFooter>
    </StyledFeedbackItem>
  )
}

export default FeedbackItem
