import React from "react"
import useWindowDimensions from "@hooks/useWindowDimensions"
import Slider from "@common/Slider"
import FeedbacksSection from "@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"
import { StyledMarginWrapper, StyledSectionTitle } from "../../common-styles"
import FeedbackItem from "./FeedbackItem"
import { 
  FEEDBACK_ITEMS, 
  FEEDBACK_SECTION_ITEMS, 
  FEEDBACK_SECTION_TITLE 
} from "../../constants"
import { StyledFeedbackWrapper } from "./styled"

const Feedback = () => {
  const { isMobile } = useWindowDimensions()
  const sliderConfig = { speed: 500 }

  return (
    <StyledMarginWrapper>
      <StyledFeedbackWrapper>
        {!isMobile && (
          <FeedbacksSection
            title={FEEDBACK_SECTION_TITLE}
            feedbacks={FEEDBACK_SECTION_ITEMS}
          />
        )}
        {isMobile && (
          <>
            <StyledSectionTitle>
              {FEEDBACK_SECTION_TITLE}
            </StyledSectionTitle>
            <Slider {...sliderConfig}>
              {FEEDBACK_ITEMS.map(props => (
                <FeedbackItem {...props} />
              ))}
            </Slider>
          </>
        )}
      </StyledFeedbackWrapper>
    </StyledMarginWrapper>
  )
}

export default Feedback
